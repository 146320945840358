var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"segmented",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('reporting.title')))])])],1)],1),_c('v-card-text',[_c('v-list',{attrs:{"flat":"","color":"white"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h3',[_vm._v("Status Reporting")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('reporting.statusReportInfo')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: MAFEG Status")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.EHG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: EHG Status")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'pdp-report',
          params: { reportType: 2 }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: PDP Status")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.PDC
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: PDC Status")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.EDC
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: EDC Status")])],1)],1),_c('v-list-item',{attrs:{"disabled":"","to":{
          name: 'pdp-report',
          params: { reportType: 999 }
        }}},[(false)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Beförderungsprozess")])],1):_vm._e()],1)],1),_c('v-list',{attrs:{"flat":"","color":"white mt-4"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h3',[_vm._v("Erweitertes Reporting")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('reporting.advancedReportInfo')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'career-development-report'
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Angestrebte Entwicklung")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf die angestrebte Entwicklung (hierarchische / laterale Entwicklung) aus den freigegebenen MAFEG- und / oder EHG-Dokumentationen. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'development-measures-report',
          params: {
            reportType: 3,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Entwicklungsmaßnahmen")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf die gewünschten Entwicklungsmaßnahmen (z. B. Trainings, WBTs, Skill-Workshops) aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'development-measures-report',
          params: {
            reportType: 5,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Performance in der Expertenrolle")]),_c('v-list-item-subtitle',[_c('em',[_vm._v("Nur relevant, wenn du direkte oder indirekte Führungskraft von Experten und Expertinnen bist (ausgenommen C&S Experten)")]),_c('br'),_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Experten und Expertinnen in Bezug auf ihre Performance in der Expertenrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'development-measures-report',
          params: {
            reportType: 6,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Auswertung der Testrolle")]),_c('v-list-item-subtitle',[_c('em',[_vm._v("Nur relevant, wenn du direkte oder indirekte Führungskraft von Mitarbeitenden mit einer offiziellen Testrolle bist.")]),_c('br'),_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre Testrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'development-measures-report',
          params: {
            reportType: 7,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Auswertung der Sonderrolle")]),_c('v-list-item-subtitle',[_c('em',[_vm._v("Nur relevant, wenn Mitarbeitende eine Sonderrolle ausüben. Eine Sonderrolle kann von Kolleginnen und Kollegen in der Position Recruiting Specialist, Care Specialist oder Process Coordinator übernommen werden.")]),_c('br'),_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre ausgeübte Sonderrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'pdc-actions-report',
          params: {
            reportType: 8,
            processType: _vm.processTypes.PDC
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: PDC Maßnahmen")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die hinterlegten Maßnahmen aus der PDC Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'ehg-targets-report',
          params: {
            reportType: 9,
            processType: _vm.processTypes.EHG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: EHG Ziele")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die hinterlegten Ziele aus der EHG Dokumentation. ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }